import React from 'react';
import './App.css';

const App: React.FunctionComponent = () => (
  <div className="App">
    <header className="App-header">
      Welcome to Play !!!
    </header>
  </div>
);

export default App;
